import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconCheckmark = ({
  title = 'Checkmark',
  color = 'green',
  viewBoxWidth = 16,
  viewBoxHeight = 12,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      d="M15.484 12v-.001V12zm0-12v0zm-2.127.48L6.194 8.05l-3.55-3.752a1.503 1.503 0 0 0-1.096-.48C.693 3.818 0 4.551 0 5.455c0 .451.173.86.454 1.156l4.645 4.909c.28.296.667.48 1.095.48.427 0 .814-.184 1.094-.48l8.258-8.726c.28-.296.454-.706.454-1.157C16 .733 15.307 0 14.452 0c-.428 0-.815.183-1.095.479z"
      fill={colors[color]}
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default IconCheckmark;
